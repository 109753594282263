module.exports = {
  BASE_URL: "https://orientalbank-cdn.laocredit.cash/api/v1",
  SOCKET_SERVER_URL: "https://orientalbank-cdn.laocredit.cash",
  // BASE_URL: 'http://192.168.35.109:8082/api/v1',
  // SOCKET_SERVER_URL: 'http://192.168.35.109:8082'

  MONEY_LOAN: [
    20000000, 50000000, 100000000, 200000000, 500000000, 900000000
  ],
  MONTH_LOAN: [6, 12, 24, 36, 48, 60, 72],
  LOAN_RATE: 0.5
};
