import {
  AlertFilled,
  CheckOutlined,
  CustomerServiceFilled,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  AuditOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Image,
  Modal,
  Table,
  Typography
} from 'antd';
import { motion } from 'framer-motion';
import moment from "moment";
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import ProfileBanner from '../../assets/profileBanner.jpg';
import tickAsset from '../../assets/tick.png';
import { ContractForm } from '../../components';
import * as actions from '../../redux/actions/auth';
import './User.scss';
import { LOAN_RATE } from '../../utils/constant';
import { currencyFormatVND } from '../../utils/CurrencyFormat';
import { useIntl } from "react-intl";
export default function User() {
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [contract, setContract] = useState({});
  const [modal, setModal] = useState(false)
  const [showDetail, setShowDetail] = useState(false);
  const [pay, setPay] = useState(false)

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/contracts');
      if (data.data.length > 0) {
        setContract(data.data[0]);
        setPay(true)
      }
    })();
  }, []);

  const handleClick = () => {
    setModal(true)
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {modal === true ? <ContractForm profile={profile} data={contract} setModal={setModal} /> : ''}
      <div className="header-container">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: '#fff' }}
        >
          {translate('Profile.label1')}
        </Typography.Text>
        <div></div>
      </div>
      <div className="avatar">
        {/* <Avatar
          size={120}
          src={profile?.kyc?.id_with_face || profile?.avatar}
        /> */}
        <Image src={ProfileBanner} preview={false} style={{ width: '100%' }} />
        {/* <Typography.Text strong style={{ fontSize: 23, color: '#121212' }}>
          {profile?.kyc?.name}
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 18, color: '#666', fontWeight: 500 }}
        >
          {profile?.phone}
        </Typography.Text> */}
      </div>
      {/* {!profile?.kyc?.name && <AlertVerify />} */}
      <div style={{ padding: 20 }}>
        <Tab
          title={translate("Profile.label2")}
          icon={
            <UserOutlined
              style={{ color: '#fff', fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => history.push('/verify')}
        />

        {/* <Tab
          title={translate('Profile.label3')}
          icon={
            <DollarCircleOutlined
              style={{ color: '#fff', fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => history.push('/my-contract')}
        /> */}

        { pay === true ? <Tab
          title={translate('Profile.label4')}
          icon={
            <AuditOutlined
              style={{ color: '#fff', fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => setShowDetail(true)}
        /> :null }
        <Tab
          title={translate('Profile.label5')}
          icon={
            <LogoutOutlined
              style={{ color: '#fff', fontSize: 25, marginRight: 20 }}
            />
          }
          onClick={() => dispatch(actions.Logout())}
        />
        {/* <motion.div
          whileTap={{ scale: 0.95, opacity: 0.4 }}
          className="log-out"
        >
          <Button
            className="log-out-btn"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            icon={<LogoutOutlined style={{ fontSize: 25, color: '#fff' }} />}
            onClick={() => dispatch(actions.Logout())}
          >
            Đăng xuất
          </Button>
        </motion.div> */}
      </div>
      {/* <div
        style={{
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image src={tickAsset} preview={false} style={{ maxWidth: 200 }} />
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
          }}
        >
          &reg; Bản quyền thuộc về Ngân hàng Oriental Bank
          <br />
        </Typography.Text>
      </div> */}
      <ModalDetailPayment
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        times={contract.times}
        amount={contract.amount}
      />
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid rgba(0, 106, 176,0.3)',
      }}
      onClick={onClick}
      className="tab"
    >
      {icon}
      <Typography.Text className="tab-text" style={{ color: '#fff' }}>
        {title}
      </Typography.Text>
    </motion.div>
  );
};
const AlertVerify = ({ status }) => {
  const history = useHistory();
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  return (
    <div className="alert-container">
      <div>
        <Typography.Text strong style={{ fontSize: 17, color: '#121212' }}>
          {translate('Profile.label6')}
        </Typography.Text>
        <div
          style={{
            borderBottom: '1px solid rgba(0, 106, 176,0.3)',
            height: 0.1,
            width: '100%',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 10px',
          }}
        >
          <div
            style={{
              flex: 1,
              minWidth: '30%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AlertFilled style={{ fontSize: 45, color: '#242ba6' }} />
          </div>
          <div>
            <Typography.Text
              style={{ fontSize: 16, fontWeight: 400, color: '#121212' }}
            >
              {translate('Profile.label7')}
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0px 10px',
          }}
        >
          <motion.div
            whileTap={{ scale: 0.9, opacity: 0.3 }}
            onClick={() => history.push('/verify')}
          >
            <Typography.Text
              strong
              style={{
                textAlign: 'right',
                fontWeight: '700',
                color: '#0022ba',
                fontSize: 17,
              }}
            >
              {translate('Profile.label8')}
            </Typography.Text>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const ModalDetailPayment = ({ visible, onCancel, times, amount }) => {
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + amount * LOAN_RATE / 100,
    period: `${moment()
      .add(index + 1, "month")
      .format("DD-MM-YYYY")}`,
  }));
  const columns = [
    {
      title: translate('Profile.label9'),
      dataIndex: "index",
      key: "index",
      render: (text) => <Typography.Text>{translate('Profile.label9')} {` ${text}`}</Typography.Text>,
    },
    {
      title: translate('Profile.label10'),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <Typography.Text strong>
          {currencyFormatVND(Math.ceil(text))}
        </Typography.Text>
      ),
    },
    {
      title: translate('Profile.label11'),
      dataIndex: "period",
      key: "period",
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={() => <></>}
    >
      <div style={{ maxHeight: 350, overflowY: "scroll" }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};